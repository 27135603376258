import Dashboard from '../views/Dashboard/Dashboard';
import AccountMapping from '../views/AccountMapping/AccountMapping';
import Bar from '../views/Bar/Bar';
import AppnexusBlacklist from '../views/Appnexus/Blacklist';
import UserManagement from '../views/UserManagement/UserManagement';
import BannerUpload from '../views/BannerUpload/BannerUpload';
import BannerReview from '../views/BannerReview/BannerReview';
import BuildAccount from '../views/BuildAccount/BuildAccount';
import XndrGtagGeneration from '../views/BuildAccount/XndrGtagGeneration';
import Campaigns from '../views/Campaigns/Campaigns';
import FormBuilder from '../views/FormBuilder/formBuilder';
import FormSelection from '../views/FormBuilder/formSelection';
import FormSubmission from '../views/FormBuilder/formSubmission';
import FormApprovalList from '../views/FormBuilder/formApprovalList';
import FormAppovalForm from '../views/FormBuilder/formApprovalForm';
import SearchCampaigns from '../views/SearchCampaigns/SearchCampaigns';
import GADSManagement from '../views/GoogleAdsManagement/GADSManagement';

const dashboardRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: 'pe-7s-graph',
        component: Dashboard,
        allowExternal: true
    },
    {
        path: '/bar',
        name: 'Bar',
        icon: 'pe-7s-calculator',
        component: Bar
    },
    {
        path: '/account-mapping',
        name: 'Account Mapping',
        icon: 'pe-7s-tools',
        component: AccountMapping,
        hidden: true
    },
    {
        path: '/users',
        name: 'User Management',
        icon: 'pe-7s-users',
        component: UserManagement
    },
    {
        path: '/banner-upload',
        name: 'Banner Upload',
        icon: 'pe-7s-upload',
        component: BannerUpload,
        allowExternal: true
    },
    {
        path: '/banner-review',
        name: 'Banner Review',
        icon: 'pe-7s-refresh-2',
        component: BannerReview,
        allowExternal: true
    },
    {
        name: 'Build Tools',
        icon: 'pe-7s-plugin',
        collapse: true,
        state: 'build',
        allowExternal: true,
        views: [
            {
                name: 'Build Xandr Account',
                path: '/build-account',
                icon: 'pe-7s-edit',
                component: BuildAccount
            },            
            {
                name: 'Xandr Tags Generation',
                path: '/xander-gtag-generation',
                icon: 'pe-7s-albums',
                component: XndrGtagGeneration
            },
            {
                name: 'Xandr BlackList',
                path: '/appnexus-blacklist-apply',
                icon: 'pe-7s-door-lock',
                component: AppnexusBlacklist
            },
            {
                name: 'Build GADS Search Campaign',
                path: '/search-campaign',
                icon: 'pe-7s-search',
                component: SearchCampaigns
            }
        ]
    },
    {
        path: '/gadsmanagement/:accountId/:locationId',
        name: 'GADS Management',
        icon: 'pe-7s-clock',
        component: GADSManagement,
        hidden: false
    },
    {
        name: 'Forms',
        icon: 'pe-7s-browser',
        collapse: true,
        state: 'forms',
        views: [
            {
                path: '/forms-builder',
                name: 'Form Builder',
                icon: 'pe-7s-albums',
                component: FormBuilder,
                hidden: true
            },
            {
                path: '/forms/formenter/:templateid',
                name: 'Form Enter',
                icon: 'pe-7s-angle-down-circle',
                component: FormSubmission,
                hidden: true
            },
            {
                path: '/forms/',
                name: 'Forms List',
                icon: 'pe-7s-angle-down-circle',
                component: FormSelection
            },
            {
                path: '/forms-approval-list/:templateid',
                name: 'Forms Approval',
                icon: 'pe-7s-refresh-2',
                component: FormApprovalList
            },
            {
                path: '/forms-approval/:submissionId',
                name: 'Forms Approval',
                icon: 'pe-7s-check',
                component: FormAppovalForm,
                hidden: true
            }
        ]
    },
    {
        path: '/ending-campaigns',
        name: 'Ending Campaigns',
        icon: 'pe-7s-clock',
        component: Campaigns
    },
    { redirect: true, path: '/', to: '/dashboard', name: 'Dashboard' }
];

export default dashboardRoutes;
